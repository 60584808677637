import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Entrepreneurs = () => {
  return (
    <Layout>
      <Seo
        title="Contratistas - BIKESAFE"
        description="Los productos de Bike Safe están fabricados con materiales de calidad superior y mano de obra experta. Ofrecemos soluciones de aparcabicicletas duraderas y de alta calidad para grandes proyectos, utilizando técnicas avanzadas de fabricación y acabados de primera clase."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Contratistas</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <h5 className="float-lg-left subheading-product uppercase">
              Calidad para grandes proyectos:
            </h5>
          </Col>
          <Col lg="6" sm="12">
            <p className="flow-text">
              Los productos de Bike Safe están diseñados y fabricados con
              materiales de calidad superior y mano de obra experta, ofreciendo
              soluciones duraderas para proyectos de cualquier escala.
              Utilizamos soldadura continua y procesos de acabado de vanguardia.
              Nuestros acabados estándar, como el galvanizado en caliente
              (conforme a la norma BS 1461:1999) y el revestimiento de poliéster
              pulverizado, aseguran la máxima resistencia y durabilidad. Todos
              nuestros aparcabicicletas se fabrican en el Reino Unido por
              especialistas en mecanizado y artesanos cualificados.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mt-5 mb-5 text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/entrepreneurs.jpg"
              className="shadow fluid rounded"
            />
            <em className="flow-text">
              "Sus plazos de entrega fueron cortos y tratar con ustedes no
              generó ningún problema."
              <strong> Lee Irvine, Irvine Utilities, Londres</strong>.
            </em>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <h3>Características de los productos de Bike Safe:</h3>
            <ul className="mt-3">
              <li className="border p-2 shade-on-hover">
                Sin mantenimiento: productos resistentes diseñados para durar en
                entornos exigentes.
              </li>
              <li className="border p-2 shade-on-hover">
                Instalación sencilla: se pueden instalar fácilmente en cualquier
                tipo de superficie.
              </li>
              <li className="border p-2 shade-on-hover">
                Diseño estético: aparcabicicletas atractivos que mejoran la
                arquitectura del entorno y fomentan su uso.
              </li>
              <li className="border p-2 shade-on-hover">
                Estacionamiento eficiente: soluciones ordenadas para maximizar
                el espacio de estacionamiento de bicicletas.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Entrepreneurs;
